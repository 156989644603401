import { useCallback, useState } from "react";
import { TableSortDirection } from "../components/Table/Table";

export default function useSorting(column: string, direction: TableSortDirection) {
  const [currentSorting, setCurrentSorting] = useState<{ column: string; direction: TableSortDirection }>({
    column,
    direction
  });

  const getSortingParams = useCallback(() => ({
    sort: currentSorting.column,
    dir: currentSorting.direction
  }), [currentSorting.column, currentSorting.direction]);

  return { currentSorting, setCurrentSorting, getSortingParams };
};
