import React, { ReactNode, useContext, useEffect, useState } from "react";

export interface AuthData {
  user: {
    token: string;
  };
  login: (email: string, password: string) => Promise<string>;
  logout: () => Promise<void>;
}

export const AuthContext = React.createContext<AuthData>(null!);

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ( { children }: { children: ReactNode } ) => {
  const [user, setUser] = useState<{ token: string }>(null!);
  const [initialized, setInitialized] = useState(false);

  const login = async (email: string, password: string) => {
    const resp = await fetch(
      `${process.env.REACT_APP_API_URL}/user/login-cms`,
      {
        method: 'POST',
        body: new URLSearchParams({ email, password })
      }
    );

    const { token } = await resp.json();

    setUser({
      token
    })

    return token;
  };

  const logout = async () => {
    setUser(null!);
  };

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    }
  }, [user]);

  useEffect(() => {
    const userJson = localStorage.getItem('user');

    if (userJson) {
      setUser(JSON.parse(userJson));
    }

    setInitialized(true);
  }, []);

  let content = <div></div>;

  if (initialized) {
    content = (
      <AuthContext.Provider value={ { user, login, logout } }>
        { children }
      </AuthContext.Provider>
    );
  }

  return content;
};

export default AuthProvider;
