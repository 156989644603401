import './Invoices.scss';

import { useEffect, useState } from 'react';
import addIcon from '../../assets/images/add-icon.svg';
import Table, { buildRows, currencyFormatter, dateFormmater } from '../../components/Table/Table';
import useApi from '../../hooks/useApi';
import usePagination from '../../hooks/usePagination';
import AddInvoiceModal, { NewInvoiceData } from './AddInvoiceModal';

export type InvoicesRow = {
  id: string;
  contactName: string;
  date: number;
  total: number;
  status: number;
  docNumber: string;
  issuer: string;
};

const Invoices = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isAddInvoiceModalOpen, setIsAddInvoiceModalOpen] = useState(false);

  const { response: apiResponse, callApi } = useApi<InvoicesRow[]>('holded/invoices');
  const { callApi: addInvoiceApiCall } = useApi('holded/create-invoice', 'POST');

  const header = [
    'Cliente',
    'N° Factura',
    'Fecha',
    'Cobrador',
    'Importe',
    'Estado'
  ];

  const handleSearch = (searchTerm: string) => {
    pagination.setCurrentPage(0);
    setSearchTerm(searchTerm);
  };

  const handleAddInvoiceClick = () => {
    setIsAddInvoiceModalOpen(true);
  };

  let rows: InvoicesRow[] = [];

  if (apiResponse) {
    rows = apiResponse;
  }

  const filteredRows = searchTerm ? rows.filter(
    row => row.contactName.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
      row.docNumber.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
  ) : rows;

  const handleAddInvoice = async (data: NewInvoiceData) => {
    const invoiceData = {
      contactId: data.contactId,
      date: data.date,
      items: [{
        name: data.description,
        subtotal: data.amount! / 1.21,
        taxes: ['s_iva_21']
      }]
    };

    await addInvoiceApiCall(undefined, { document: JSON.stringify(invoiceData)});
    await callApi();

    setIsAddInvoiceModalOpen(false);
  };

  let totalInvoices = rows.length;

  const pagination = usePagination(totalInvoices);

  useEffect(() => {
    callApi();
  }, [callApi]);

  return (
    <div id="app-content">
      <AddInvoiceModal
        isOpen={isAddInvoiceModalOpen}
        onClose={() => setIsAddInvoiceModalOpen(false)}
        onAddInvoice={handleAddInvoice}
      />
      <div id="header" className="d-flex justify-content-between">
        <h1>Invoices</h1>
        <span>Total de facturas: {totalInvoices}</span>
      </div>
      <Table
        actions={
          <div className="actions px-4 py-3 rounded-4 bg-white shadow-sm">
            <span
              className="d-flex flex-col mx-2 py-2 px-3 rounded-pill rounded-4 selected"
              onClick={handleAddInvoiceClick}
            >
              <img src={addIcon} alt="Subir factura" className="me-2 align-middle" />
              Subir factura
            </span>
          </div>
        }
        header={header}
        rows={buildRows(
          filteredRows.map(row => ({
              ...row,
            _id: row.id,
            date: dateFormmater(new Date(row.date)),
            total: currencyFormatter(row.total),
            status: row.status === 0 ? 'pendiente' : 'pagada'
          })),
          ['contactName', 'docNumber', 'date', 'issuer', 'total', 'status']
        )}
        pagination={pagination}
        totalEntries={totalInvoices}
        onSearch={handleSearch}
      />
    </div>
  );
};

export default Invoices;
