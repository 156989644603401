import './Pagination.scss';
import classNames from 'classnames';
import { MouseEvent } from 'react';

export const ENTRIES_PER_PAGE = 10;

export interface PaginationProps {
  currentPage: number;
  totalEntries: number;
  entriesPerPage?: number;
  hasNextPage: (totalPages: number) => boolean;
  hasPrevPage: () => boolean;
  goToNext: (ev: MouseEvent, totalPages: number) => void;
  goToPrev: (ev: MouseEvent, ) => void;
  goToPage: (ev: MouseEvent, page: number) => void;
}

const getPageLinkClasses = (disabled = false, active = false, arrow = false) => classNames({
  'page-link': true,
  'text-body-tertiary': !active && !arrow,
  'text-black-50': disabled,
  'text-bg-secondary': active,
  'text-body': active || (arrow && !disabled),
  'border': true,
  'border-0': true,
  'bg-white': true,
  'p-1': true,
  'fw-bold': arrow,
  'pe-none': active || disabled
});


const Pagination = (
  { currentPage, totalEntries, entriesPerPage = ENTRIES_PER_PAGE, hasPrevPage, hasNextPage, goToNext, goToPrev, goToPage }: PaginationProps
) => {
  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  const firstEntry = currentPage * entriesPerPage + 1;
  const lastEntry = Math.min((currentPage + 1) * entriesPerPage, totalEntries);

  const pagesRangeWidth = 2;

  const showFirstPageSeparately = currentPage > 3;
  const showLastPageSeparately = currentPage + pagesRangeWidth + 1 < totalPages - 1;

  const shownPages: number[] = [];
  for (
    let page = currentPage - pagesRangeWidth > 1 ? currentPage - pagesRangeWidth : 0;
    page < (showLastPageSeparately ? currentPage + pagesRangeWidth + 1 : totalPages);
    page++
  ) {
    shownPages.push(page);
  }

  return totalPages > 1 ? (
    <div id="pagination-counter" className="d-flex justify-content-between align-items-center ps-3 pe-3">
      <span>{ firstEntry }-{ lastEntry } de { totalEntries }</span>
      <nav aria-label="Navigation">
        <ul className="pagination pagination-sm justify-content-center m-0">
          <li className="page-item">
            <a className={ getPageLinkClasses(!hasPrevPage(), false, true) } onClick={ (ev) => goToPrev(ev) } href="#">
              &lt;
            </a>
          </li>
          { showFirstPageSeparately ? (
              <>
                <li className="page-item" key={ 0 }>
                  <a className={ getPageLinkClasses(false, 0 === currentPage) } onClick={ (ev) => goToPage(ev, 0) }
                     href="#">
                    1
                  </a>
                </li>
                ...
              </>
          ) : null }
          { shownPages.map(page => (
            <li className="page-item" key={ page }>
              <a className={ getPageLinkClasses(false, page === currentPage) } onClick={ (ev) => goToPage(ev, page) }
                 href="#">
                { page + 1 }
              </a>
            </li>
          )) }
          { showLastPageSeparately ? (
              <>
                ...
                <li className="page-item" key={ totalPages }>
                  <a className={ getPageLinkClasses(false, totalPages - 1 === currentPage) }
                     onClick={ (ev) => goToPage(ev, totalPages - 1) } href="#">
                    { totalPages }
                  </a>
                </li>
              </>
          ) : null }
          <li className="page-item">
            <a className={ getPageLinkClasses(!hasNextPage(totalPages), false, true) }
               onClick={ (ev) => goToNext(ev, totalPages) } href="#">
              &gt;
            </a>
          </li>
        </ul>
      </nav>
    </div>
  ) : null;
};

export default Pagination;
