import { ReactNode } from 'react';
import ReactModal from 'react-modal';
import closeIcon from '../../assets/images/close-icon.svg';
import Button from '../Button/Button';

export type ModalProps = {
  isOpen?: boolean;
  okButtonLabel?: string;
  onClose?: () => void;
  onAccept?: () => void;
  title?: string;
  children?: ReactNode;
  width?: number;
  height?: number;
};

const Modal = ({ isOpen = false, okButtonLabel = 'OK', onClose, onAccept, title, children, height = 450, width = 536 }: ModalProps) => {
  return <ReactModal
    isOpen={isOpen}
    onRequestClose={onClose}
    style={{
      overlay: {
        backgroundColor: '#EDEDEDCF',
        zIndex: 100
      },
      content: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '21.516px',
        width: `${width}px`,
        height: `${height}px`,
        margin: '0 auto',
        padding: 0,
        borderRadius: 0,
        border: 0,
        zIndex: 101
      }
    }}
  >
    <div className="d-flex flex-column align-items-stretch h-100">
      <div className="d-flex flex-row p-4 border-bottom justify-content-between">
        <span className="fw-semibold">{title}</span>
        <img
          style={{ cursor: 'pointer' }}
          src={closeIcon}
          alt="Close modal"
          onClick={onClose}
        />
      </div>
      <div className="flex-grow-1 p-4">
        {children}
      </div>
      <div
        className="d-flex flex-row-reverse border-top mt-auto px-4"
        style={{ paddingTop: '9px', paddingBottom: '9px' }}
      >
        <Button
          style={{ backgroundColor: '#E4FD3D', borderRadius: '4px', border: 0, padding: '12px 16px', color: '#000', lineHeight: '21.516px', height: 'auto' }}
          onClick={onAccept}
          value={okButtonLabel}
        />
      </div>
    </div>
  </ReactModal>
};

export default Modal;
