import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useAuth } from "../auth/AuthProvider";

export interface ApiArrayResponse<T> {
  results: T[],
  total: number;
  shownTotal: number;
}

export default function useApi<ResponseType>(path: string, method?: 'GET' | 'POST' | 'DELETE' | 'PUT', contentType = 'application/x-www-form-urlencoded;charset=utf-8') {
  const [response, setResponse] = useState<ResponseType>(null!);
  const [status, setStatus] = useState('idle');
  const [error, setError] = useState<string>(null!);
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const buildUrl = (path: string, queryParams?: { [param: string]: string | number }) => {
    let url = `${process.env.REACT_APP_API_URL}/${path}`;

    if (queryParams) {
      const params = [];
      for (let param in queryParams) {
        params.push(`${encodeURIComponent(param)}=${encodeURIComponent(queryParams[param])}`);
      }

      url += '?' + params.join('&');
    }
    console.log(url);

    return url;
  };

  const callApi = useCallback(
    async (queryParams?: { [param: string]: string | number }, bodyParams?: { [param: string]: any }) => {
      try {
        setStatus('pending');

        const url = buildUrl(path, queryParams);
let body = undefined;

        if (bodyParams) {
          body = [];
          for (let param in bodyParams) {
            body.push(`${encodeURIComponent(param)}=${encodeURIComponent(bodyParams[param])}`);
          }
        }

        const response = await fetch(url, {
          method,
          headers: {
            token: `${user.token}`,
            'Content-Type': contentType
          },
          body: body ? body.join('&') : undefined
        });
        if (response.ok) {
          setResponse(await response.json());
          setStatus('success')
        } else {
          setError(await response.text());
          setStatus('error');
          if (response.status === 401) {
            await logout();
            navigate('/login', { state: { from: location } });
          }
        }
      } catch (e) {
        setError(JSON.stringify(e));
        setStatus('error');
      }
    },
    [path, method, contentType, user.token, location, logout, navigate]
  );

  const postApi = useCallback(
    async (attributtes: any) => {
      try {
        setStatus('pending');

        var formBody = [];

        for (var property in attributtes) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(attributtes[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }

        const url = `${process.env.REACT_APP_API_URL}/${path}`;
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'token': `${user.token}`,
          },
          body: formBody.join("&")
        });

        if (response.ok) {
          setResponse(await response.json());
          setStatus('success')
        } else {
          setError(await response.text());
          setStatus('error');
          if (response.status === 401) {
            await logout();
            navigate('/login', { state: { from: location } });
          }
        }
      } catch (e) {
        setError(JSON.stringify(e));
        setStatus('error');
      }
    },
    [path, user.token, location, logout, navigate]
  );

  return { callApi, postApi, status, error, response };
};
