import React, { useEffect, useState } from 'react';
import Table, { buildRows } from '../../components/Table/Table';
import usePagination from '../../hooks/usePagination';
import useApi from '../../hooks/useApi';

export type CollaboratorRow = {
  _id: string;
  fullname: string;
  business_name: string;
  nif: string;
  email: string;
  phone: string;
  token: string;
};

const ROWS_PER_PAGE = 3;

const Collaborators = (): React.ReactElement => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const pagination = usePagination(ROWS_PER_PAGE);

  const { status, response, callApi } = useApi<{ users: CollaboratorRow[] }>('user/list/collaborator');

  let rows: CollaboratorRow[] = [];

  let totalRows = 0;
  let shownRows = ROWS_PER_PAGE;

  if (response) {
    const { users } = response;
    totalRows = users.length;
    rows = users;
  }

  const header = [
    'Nombre',
    'Negocio',
    'NIF',
    'Email',
    'Teléfono',
    'Token'
  ];

  useEffect(() => {
    callApi();
  }, [callApi]);

  const handleSearch = (searchTerm: string) => {
    pagination.setCurrentPage(0);
    setSearchTerm(searchTerm);
  };

  return (
    <React.Fragment>
      <div id="app-content">
        <div id="header" className="d-flex justify-content-between">
          <h1>Colaboradores</h1>
          <span>Total de colaboradores: {totalRows} </span>
        </div>
        <Table
          header={header}
          rows={buildRows(
            rows
              .filter(row => !searchTerm || row.fullname.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)
              .slice(
                pagination.currentPage * ROWS_PER_PAGE,
                (pagination.currentPage + 1) * ROWS_PER_PAGE,
              ),
            ['fullname', 'business_name', 'nif', 'email', 'phone', 'token']
          )}
          totalEntries={totalRows}
          pagination={pagination}
          loading={status === 'pending'}
          onSearch={handleSearch}
        />
      </div>
    </React.Fragment>
  );
};

export default Collaborators;
