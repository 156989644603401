import './Clients.scss';

import React, { useEffect, useState } from 'react';
import Table, { buildRows, currencyFormatter, dateFormmater, TableSortColumn, TABLE_SORT_DIRECTION_ASC, TABLE_SORT_DIRECTION_DESC } from '../../components/Table/Table';
import useApi, { ApiArrayResponse } from '../../hooks/useApi';
import usePagination from '../../hooks/usePagination';
import useSorting from '../../hooks/useSorting';

export interface ClientsRow {
  _id: string;
  name: string;
  email: string;
  user_created_at: Date;
  sales_amount: number;
  sales_count: number;
  likes: number;
  [key: string]: string | number | Date | boolean;
};

const Clientes = (): React.ReactElement => {
  const { currentSorting, setCurrentSorting, getSortingParams } = useSorting('sales_count', TABLE_SORT_DIRECTION_DESC);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { status: callApiStatus, response: apiResponse, callApi } = useApi<ApiArrayResponse<ClientsRow>>('user-stats');
  const pagination = usePagination();

  const header = ['Nombre', 'Correo electrónico', 'Fecha de registro', 'Dinero gastado', 'Compras totales', 'Likes'];

  let rows: ClientsRow[] = [];
  let totalRows = 0;
  let shownRows = 0;

  if (apiResponse) {
    let { results, total, shownTotal } = apiResponse;
    totalRows = total;
    shownRows = shownTotal;
    rows = results;
  }

  const handleSearch = (searchTerm: string) => {
    pagination.setCurrentPage(0);
    setSearchTerm(searchTerm);
  };

  const handleSorting = async (sorting: TableSortColumn) => {
    setCurrentSorting(sorting);
  };

  useEffect(() => {
    callApi({ ...getSortingParams(), page: pagination.currentPage, search: searchTerm });
  }, [callApi, pagination.currentPage, getSortingParams, searchTerm]);

  return (
    <React.Fragment>
      <div id="app-content">
        <div id="header" className="d-flex justify-content-between mb-3">
          <h1>Clientes</h1>
          <span>Total de usuarios: {totalRows}</span>
        </div>
        <Table
          header={header}
          rows={buildRows(
            rows.map(row => ({
              ...row,
              'user_created_at': row['user_created_at'] instanceof Date ? dateFormmater(row['user_created_at']) : '',
              'sales_amount': currencyFormatter(row['sales_amount'])
            })),
            ['name', 'email', 'user_created_at', 'sales_amount', 'sales_count', 'likes']
          )}
          sorting={[
            {
              key: 'sales_count_desc',
              column: 'sales_count',
              label: 'Clientes que más compran',
              direction: TABLE_SORT_DIRECTION_DESC,
              selected: currentSorting.column === 'sales_count' &&
                currentSorting.direction === TABLE_SORT_DIRECTION_DESC
            },
            {
              key: 'sales_counts_asc',
              column: 'sales_count',
              label: 'Clientes que menos compran',
              direction: TABLE_SORT_DIRECTION_ASC,
              selected: currentSorting.column === 'sales_count' &&
                currentSorting.direction === TABLE_SORT_DIRECTION_ASC
            }
          ]}
          onSort={handleSorting}
          loading={callApiStatus === 'pending'}
          pagination={pagination}
          totalEntries={shownRows}
          onSearch={handleSearch}
        />
      </div>
    </React.Fragment>
  )
}

export default Clientes;
