import './TableSearch.scss';

import { useMemo } from "react";

import debounce from 'lodash/debounce';

export type TableSearchHandler = (searchTerm: string) => void;

export interface TableSearchProps {
  onSearch: TableSearchHandler;
}

const TableSearch = ({ onSearch }: TableSearchProps) => {
  const handleSearch = useMemo(
    () => debounce((ev) => onSearch ? onSearch(ev.target.value) : null, 500),
    [onSearch]
  );

  return (
    <div className="search-container px-4 py-3 rounded-4 shadow-sm d-flex align-items-center">
      <i className="bi bi-search me-3"></i>
      <input type="text" onKeyUp={ handleSearch } placeholder="Buscar" className="p-0 m-0 h-auto border-0" />
    </div>
  );
};

export default TableSearch;
