import './Table.scss';

import React, { ReactNode } from 'react';

import TableButtons from './TableButtons';
import TableLoadingPlaceholder from './TableLoadingPlaceholder';
import Pagination, { ENTRIES_PER_PAGE } from '../Pagination/Pagination';
import TableSearch, { TableSearchHandler } from './TableSearch';
import { BusinessesRow } from '../../containers/Businesses/Businesses';
import deleteIcon from '../../assets/images/delete.svg';
import editIcon from '../../assets/images/pencil.svg';
import { format } from 'date-fns';

export type TableHeader = string[];

export function currencyFormatter(value: number) {
  return `€${value.toLocaleString('es-ES')}`;
};

export function dateFormmater(value: Date) {
  return format(value, 'dd/MM/yyyy');
}

export const booleanFormatterFactory = (trueValue: string | JSX.Element, falseValue: string | JSX.Element) =>
  (value: boolean) => value ? trueValue : falseValue;

export interface TableRow {
  id: string;
  deleteId?: any,
  columns: {
    name: string;
    value: React.ReactNode;
  }[];
}

export const TABLE_SORT_DIRECTION_ASC = 1;
export const TABLE_SORT_DIRECTION_DESC = -1;
export type TableSortDirection = 1 | -1;

export interface TableSortColumn {
  key: string;
  column: string;
  label: string;
  direction: TableSortDirection;
  selected: boolean;
}

export type TableSortHandler = (column: TableSortColumn) => void;

export interface Filter {
  column: string;
  value: any;
}

export interface TableFilter {
  key: string;
  label: string;
  filter: Filter;
  selected: boolean;
}

export type TableFilterHandler = (filter: TableFilter) => void;

export interface TableProps {
  header: TableHeader;
  rows: TableRow[];
  sorting?: TableSortColumn[];
  onSort?: TableSortHandler;
  appliedFilter?: TableFilter;
  filters?: TableFilter[];
  onFilter?: TableFilterHandler;
  loading?: boolean;
  pagination?: any;
  totalEntries: number;
  onSearch?: TableSearchHandler;
  onChangeSepa?: Function;
  onDeleteItem?: Function;
  onUpdateItem?: Function;
  actions?: ReactNode;
}

export interface DbRow {
  _id: string;
  [key: string]: string | React.ReactNode | number | boolean | Date;
}

export const buildRows = <T extends DbRow,>(dbRows: T[], columns: string[]): TableRow[] => {
  return dbRows.map(row => {
    const { _id: id } = row;
    const { deleteId } = row;

    return {
      id: id,
      deleteId: deleteId ? deleteId : '',
      columns: columns.map(key => {
        let rawValue = row[key];
        let value: string | JSX.Element = '';

        if (typeof rawValue === 'string') {
          value = rawValue;
        } else if (typeof rawValue === 'number') {
          value = rawValue.toLocaleString('es-ES');
        } else if (React.isValidElement(rawValue)) {
          value = rawValue;
        } else {
          value = (rawValue || '').toString();
        }

        return { name: key, value };
      })
    };
  });
};

const Table = ({ actions, header, rows, sorting, onSort, filters, onFilter, loading, pagination, totalEntries, onSearch, onChangeSepa, onDeleteItem, onUpdateItem }: TableProps) => {
  console.log(rows);
  return (
    <>
      <div className="d-flex flex-row justify-content-between">
        {actions ? actions : null}
        {sorting || filters ? (
          <div className="d-flex flex-row mw-100 align-items-center">
            {sorting ? (
              <TableButtons label="Ordenar por" buttons={sorting} onClickHandler={onSort} />
            ) : null}
            {
              filters ? (
                <TableButtons label="Filtrar por" buttons={filters} onClickHandler={onFilter} />
              ) : null
            }
          </div>
        ) : null}
        {onSearch ? <TableSearch onSearch={onSearch} /> : null}
      </div>
      <table className="table mt-4 overflow-hidden rounded-4 shadow-sm">
        <thead className="table-light">
          <tr>
            {header.map(th => (
              <th className="p-3 px-4 align-middle" scope="col" key={th}>{th}</th>
            ))}

            {(onDeleteItem || onUpdateItem) &&
              <th className="p-3 ps-4 pe-4 align-middle" scope="col" key={'actions'}>{''}</th>
            }
          </tr>
        </thead>
        <tbody>
          {loading ?
            <TableLoadingPlaceholder rows={ENTRIES_PER_PAGE} columns={header.length} /> :
            rows.map(row => (
              <tr key={row.id}>
                {row.columns.map(column => (
                  <td className="p-3 ps-4 pe-3 align-middle" key={`${row.id}-${column.name}`}>
                    {column.value}
                  </td>
                ))}

                {onDeleteItem &&
                  <td className="p-3 ps-4 pe-3 align-middle" key={`${row.id}-delete`}>
                    <span><img src={deleteIcon} alt={'Borrar este registro'} style={{ width: 16, cursor: 'pointer' }} onClick={() => { onDeleteItem && onDeleteItem(row.deleteId) }} /></span>
                  </td>
                }

                {onUpdateItem &&
                  <td className="p-3 ps-4 pe-3 align-middle" key={`${row.id}-delete`}>
                    <span><img src={editIcon} alt={'Editar este registro'} style={{ width: 12, cursor: 'pointer' }} onClick={() => { onUpdateItem && onUpdateItem({ id: row.id, name: row.columns[0].value, credits: row.columns[7].value }) }} /></span>
                  </td>
                }
              </tr>
            ))}
        </tbody>
        {pagination && rows && !loading ? (
          <tfoot>
            <tr>
              <td colSpan={header.length}>
                <Pagination {...pagination} totalEntries={totalEntries} />
              </td>
            </tr>
          </tfoot>
        ) : null}
      </table>
    </>
  )
};

export default Table;
