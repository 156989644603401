import { es } from 'date-fns/locale/es';
import { useEffect, useState } from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import InputText from '../../components/InputText/InputText';
import Modal from '../../components/Modal/Modal';
import useApi from '../../hooks/useApi';

import 'react-datepicker/dist/react-datepicker.module.css';

registerLocale('es', es);
setDefaultLocale('es');

export type HoldedContact = {
  id: string;
  name: string;
};

export type NewInvoiceData = {
  contactId?: string;
  amount?: number;
  date?: Date;
  description?: string;
};

export type AddInvoiceModalProps = {
  isOpen?: boolean;
  onClose?: () => void;
  onAddInvoice?: (data: NewInvoiceData) => void;
};

const AddInvoiceModal = ({ isOpen = false, onClose, onAddInvoice }: AddInvoiceModalProps) => {
  const [invoiceData, setInvoiceData] = useState<NewInvoiceData>();

  const { response: companies, callApi } = useApi<HoldedContact[]>('holded/contacts');

  const updateInvoiceDataField = (field: string, value: any) =>
    setInvoiceData(data => ({ ...data, [field]: value }));

  useEffect(() => {
    callApi()
  }, [callApi]);

  const handleOnAccept = () => {
    if (onAddInvoice) {
      onAddInvoice(invoiceData!);
    }

    setInvoiceData(undefined);
  }

  return (
    <Modal
      title="Subir factura"
      okButtonLabel="Subir factura"
      isOpen={isOpen}
      onClose={onClose}
      onAccept={handleOnAccept}
    >
      <div
        className="d-flex flex-column"
        style={{ gap: '16px' }}
      >
        <select
          className="border"
          style={{ borderColor: '#E4E4E4', borderRadius: '12px', padding: '12px 16px' }}
          placeholder="Empresa"
          value={invoiceData?.contactId}
          onChange={ev => updateInvoiceDataField('contactId', ev.target.value ? ev.target.value : undefined)}
        >
          <option value="">Empresa</option>
          {companies ? companies.map(company => (
            <option
              key={company.id}
              value={company.id}
            >
              {company.name}
            </option>
          )) : null}
        </select>
        <div
          style={{ gap: '16px' }}
          className="d-flex align-items-stretch"
        >
          <div
            className="flex-fill border px-3 py-1 position-relative"
            style={{ borderRadius: '12px' }}
          >
            <InputText
              value={invoiceData?.amount ?? ''}
              onChange={ev => updateInvoiceDataField('amount', ev.target.value)}
              style={{ height: 'auto', border: 0, padding: 0 }}
              placeholder="Total"
            />
            <span style={{ color: '#ADAAAA', userSelect: 'none', position: 'absolute', right: '12px', top: '50%', transform: 'translateX(-100%) translateY(-50%)' }}>€</span>
          </div>
          <div
            className="flex-fill border px-3 py-1"
            style={{ borderRadius: '12px' }}
          >
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={invoiceData?.date}
              popperPlacement="bottom"
              onChange={newDate => updateInvoiceDataField('date', newDate ? new Date(newDate) : undefined)}
              customInput={
                <InputText
                  placeholder="Fecha"
                  style={{ height: 'auto', border: 0, padding: 0 }}
                />
              }
            />
          </div>
        </div>
        <div
          style={{ gap: '16px' }}
          className="d-flex align-items-stretch"
        >
          <div
            className="flex-fill border px-3 py-1 position-relative"
            style={{ borderRadius: '12px' }}
          >
            <InputText
              placeholder="Detalle factura"
              value={invoiceData?.description ?? ''}
              onChange={ev => updateInvoiceDataField('description', ev.target.value)}
              style={{ height: 'auto', border: 0, padding: 0 }}
            />
          </div>
        </div>
      </div>
    </Modal >
  );
};

export default AddInvoiceModal;
